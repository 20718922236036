import React from 'react'
import NewsletterSection from '../NewsletterSection'
import './styles.scss'

function Pricing(props) {
  return (
    <div className="columns is-centered is-variable is-5">
      {props.items.map((item, index) => (
        <div className="Pricing__column column" key={index}>
          <div
            className={
              'Pricing__card card' +
              (item.emphasized === true ? ' emphasized' : '')
            }
          >
            <div className="Pricing__card-content card-content">
              <div className="Pricing__period has-text-weight-bold">
                <span>{item.planName}</span>
              </div>
              <div className="Pricing__price has-text-weight-bold">
                <span className="Pricing__price-symbol is-size-3">$</span>
                <span className="is-size-1">{item.price}</span>
                <span className="Pricing__price-month is-size-4">
                  {item.unit}
                </span>
                <br />
                <span className="is-size-6">
                  {' '}
                  {index === 0 ? (
                    <span>
                      {item.subtitle}
                      {/* <br />
                      <span style={{ color: 'red', fontSize: '0.85rem' }}>
                        New price coming July 1! Sign up now to lock in the
                        current rate.
                      </span> */}
                    </span>
                  ) : (
                    <span>{item.subtitle}</span>
                  )}
                </span>
              </div>
              {/* <p className="Pricing__description">{item.description}</p> */}
              <ul className="list-unstyled">
                {item.features.map(feature => {
                  return (
                    <li
                      key={feature}
                      style={{
                        margin: 'auto 0px',
                        color: `${
                          /(coming soon)/g.test(feature) ? 'grey' : ''
                        }`,
                      }}
                      className="Pricing__feature"
                    >
                      {feature}
                    </li>
                  )
                })}
              </ul>
              <br />
              {/* <button
                className="Pricing__button button is-medium is-primary"
                onClick={() => props.onChoosePlan(item.id)}
              >
                {item.cta}
              </button> */}
              <NewsletterSection
                centered={false}
                size={4}
                buttonText={item.cta}
                subscribedMessage={props.subscribedMessage}
                inputPlaceholder={'Email Address'}
                formName={`pricing-${item.planName}`}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Pricing
