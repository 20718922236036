import React from 'react'
import PricingSection from '../components/PricingSection'
import FaqSection from '../components/FaqSection'
import Navbar from '../components/Navbar'
import Divider from '../components/Divider'
import Footer from '../components/Footer'
import SEO from '../components/seo'

function PricingPage({ data, location }) {
  return (
    <>
      <Navbar color="white" spaced={true} data={data.allContentfulNavbar} />
      <SEO title="Pricing" />
      <PricingSection
        color="white"
        size="small"
        title="Pricing"
        subtitle={data.allContentfulPricingPage.edges[0].node.subtitle.subtitle}
        data={data.allContentfulPricingPage.edges[0].node}
      />
      <FaqSection
        color="white"
        size="small"
        title="Frequently Asked Questions"
        subtitle=""
        data={data.allContentfulFaq.edges[0].node}
      />
      <Divider color="light" />
      <Footer
        color="light"
        size="medium"
        logo={data.allContentfulNavbar.edges[0].node.brandLogo}
        description={data.allContentfulFooter.edges[0].node.description}
        copyright={
          '© ' +
          data.allContentfulFooter.edges[0].node.companyName +
          ` ${new Date().getFullYear()}`
        }
        socialPages={data.allContentfulFooter.edges[0].node.socialPages}
        socialImages={data.allContentfulFooter.edges[0].node.socialImages}
        driftAppId={data.allContentfulFooter.edges[0].node.driftAppId}
      />
    </>
  )
}

export default PricingPage

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulPricingPage {
      edges {
        node {
          prices
          units
          ctas
          planNames
          basicFeatures
          proFeatures
          enterpriseFeatures
          minSpend
          subtitle {
            subtitle
          }
        }
      }
    }
    allContentfulFaq {
      edges {
        node {
          questions
          answers
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
