import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import Pricing from './../Pricing'
import './styles.scss'

function PricingSection(props) {
  let tiers = props.data
  let items = []
  tiers.prices.forEach((_, index) => {
    items.push({
      id: tiers.planNames[index],
      planName: tiers.planNames[index],
      price: tiers.prices[index],
      unit: tiers.units[index],
      subtitle: tiers.minSpend[index],
      features:
        index === 0
          ? tiers.basicFeatures
          : index === 1
          ? tiers.proFeatures
          : index === 2
          ? tiers.enterpriseFeatures
          : '',
      cta: tiers.ctas[index],
    })
  })
  return (
    <Section color={props.color} size={props.size} id="pricing">
      <div className="PricingSection__container container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Pricing
          // buttonText="Choose"
          onChoosePlan={planId => {
            // Add your own payments logic here
            // alert(`You chose the plan "${planId}"`)
            window.open(
              'https://app.akutehealth.com/signup',
              '_blank',
              'noopener noreferrer'
            )
          }}
          items={items}
        />
      </div>
    </Section>
  )
}

export default PricingSection
