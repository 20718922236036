import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import Faq from './../Faq'
import './styles.scss'

function FaqSection(props) {
  let data = props.data
  let items = []
  data.questions.forEach((question, index) => {
    items.push({
      question: question,
      answer: data.answers[index],
    })
  })
  return (
    <Section color={props.color} size={props.size}>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
        integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
        crossorigin="anonymous"
      />
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Faq items={items} />
      </div>
    </Section>
  )
}

export default FaqSection
